import "$styles/index.css"

import { ready } from "./ready"
import { updateMeetupButtons } from "./meetup"
import "./toggle-theme"
import "./toggle-menu"

import "lite-youtube-embed"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!", components)

ready(updateMeetupButtons)
