export const updateMeetupButtons = () => {
  document.querySelectorAll("[data-eventtime]").forEach((el) => {
    if (el.dataset.eventtime?.length) {
      const eventTime = new Date(el.dataset.eventtime)
      const labelEl = el.querySelector("[data-label]")

      if (eventTime > Date.now()) {
        el.classList.remove("is-past")
        el.classList.add("is-future")
        labelEl.textContent = el.dataset.future
      } else {
        el.classList.add("is-past")
        el.classList.remove("is-future")
        labelEl.textContent = el.dataset.past
      }
    }
  })
}
